.ant-table-wrapper {
  height: 100%;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}

.ant-table {
  height: 100%;
}
