.truncate {
  max-width: 800px;
  white-space: nowrap;
  overflow: scroll;
  //   text-overflow: ellipsis;
}

.ant-upload.ant-upload-drag {
  height: 100%;
}

#step-wrapper-container > span {
  width: 100%;
}

.clickable-step {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
